import { isAxiosError } from 'axios'

export enum ErrorType {
  InvalidQR = 'InvalidQR',
  InvalidPassword = 'InvalidPassword',
  Unknown = 'Unknown',
  userNotFound = 'ERROR_USER_NOT_FOUND',
  userLogin = 'ERROR_USER_LOGIN',
  badRequest = 'ERROR_USER_BAD_REQUEST',
  invalidCredentials = 'Invalid user credentials'
}

export function is401 (e: unknown) {
  return isAxiosError(e) && e.response?.status === 401
}
