import {
  AtlasH1,
  PrimaryButtonAtom,
  TextBoxMolecule
} from '@justomx/atlas-components'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { FlexAtom } from 'components/atoms/Flex.atom'

interface UserAndPassProps {
  onLogin: (username: string, password: string) => void
  onLoginKeycloack: (username: string, password: string) => void
}

export function UserAndPassPage ({ onLogin }: UserAndPassProps) {
  const { t } = useTranslation('global')
  const [username, setUsername] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')

  const handleLogin = () => {
    onLogin(username, password)
  }

  return (
    <>
      <FlexAtom $gap={24}>
        <AtlasH1>Inicia sesión</AtlasH1>

        <TextBoxMolecule
          tabIndex={-1}
          type="email"
          label={t('home.user')}
          onChange={(value) => {
            setUsername(value)
          }}
        />
        <TextBoxMolecule
          tabIndex={-1}
          type="password"
          label={t('home.password')}
          onChange={(value) => {
            setPassword(value)
          }}
        />
        <PrimaryButtonAtom onClick={handleLogin} type="button">
          {t('home.login')}
        </PrimaryButtonAtom>
      </FlexAtom>
    </>
  )
}
